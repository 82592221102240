<template>
    <content-wrapper>
        <settings-list-group
            :items="levels"
        />
    </content-wrapper>
</template>

<script>
import ContentWrapper from '@/components/ContentWrapper'
import SettingsListGroup from '@apps/settings/components/SetingsList/SettingsListGroup'
import { LEVEL } from '@/widgets/components/WIcon/icons'

export default {
    name: 'ScheduleLevels',
    components: { SettingsListGroup, ContentWrapper },
    computed: {
        levels() {
            const levels = this.$store.getters['school/levels']
            const items = []

            levels.forEach(o => {
                items.push({
                    icon: LEVEL,
                    title: o.title,
                    subtitle: o.description,
                    color: 'primary',
                    to: { name: 'manage.school.schedule.level', params: { uuid: o.uuid } }
                })
            })

            return items
        },
        title() {
            return this.$trans('Schedule')
        },
        breadcrumbs() {
            return [
                {
                    text: this.$trans('Schedule')
                }
            ]
        }
    }
}
</script>

<style lang="scss">

</style>
